/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
import React, {useState, useContext, useEffect} from 'react'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

import {navigate, Link} from 'gatsby'
import styled from 'styled-components'
import useForm from '../components/Hooks/useForm'
import AuthContext from '../components/Context/AuthContext'
import CartContext from '../components/Context/CartContext'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import PageHeader from '../components/Layout/PageHeader'
import {Inner} from '../components/styles'
import {isOnMobile} from '../utils'

// After logging in, never redirect back to these pages
const redirectBlacklist = ['/autres/', '/app/']

const FormStyles = styled.div`
  margin-bottom: 60px !important;
  .ui.label.label {
    min-width: 160px;
    text-transform: uppercase;
    display: grid;
    align-items: center;
    margin: 0;
    border-radius: 3px 0 0 3px;
  }
  .form-footer {
    text-align: center;
  }
  .ui.fluid.labeled {
    input {
      border-radius: 0 3px 3px 0;
    }
  }
  .ui.fluid.error {
    input {
      border-color: #d4909e;
      border-bottom-right-radius: 0;
    }
    .label {
      background-color: #d4909e;
      border-bottom-left-radius: 0;
    }
  }
  @media (max-width: 500px) {
    .ui.fluid.input {
      display: grid;
      grid-template-columns: 1fr;
    }
    .ui.label.label {
      min-width: auto;
      border-top-right-radius: 5px !important;
      border-top-left-radius: 5px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .ui.form .field .ui.input input {
      width: 100% !important;
      border: 1px solid rgba(34, 36, 38, 0.15) !important;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
`

const LoginPage = ({location}) => {
  const [loading, setLoading] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)
  const [apiError, setApiError] = useState(false)
  const {setCartState} = useContext(CartContext)
  const {performLogin, loggedInState} = useContext(AuthContext)

  // Function that checks if user is logged in and redirects if true
  const loginCheck = () => {
    if (loggedInState === true) {
      console.log('Logged in')

      // We check if a fromUrl was passed from wherever the user clicked on "Log in"
      // But if the fromUrl contains one of the redirectBlacklist URL's, we don't use it
      if (
        location.state &&
        location.state.fromUrl &&
        !redirectBlacklist.some(badurl =>
          location.state.fromUrl.includes(badurl),
        )
      ) {
        setCartState('cart')
        navigate(`${location.state.fromUrl}`, {
          replace: true,
          state: {showNotice: 'login'},
        })
      } else
        navigate(`/profile/`, {replace: true, state: {showNotice: 'login'}})
    }
  }

  useEffect(() => {
    loginCheck()
  }, [loggedInState])

  const submitCallback = () => {
    performLogin(
      values.email,
      values.password,
      setLoading,
      setApiError,
      loginCheck,
    )
  }

  const {values, handleChange, handleSubmit, errors} = useForm(
    submitCallback,
    validate,
  )

  const handleErrors = errorResponse => {
    if (errorResponse.content) {
      return (
        <Message error>
          <p
            dangerouslySetInnerHTML={{
              __html: errorResponse.content,
            }}
          />
        </Message>
      )
    }

    return <Message error header="Sorry" content="Cannot login at this time." />
  }

  return (
    <Layout location={location}>
      <SEO location={location} title="Login" />
      <FormStyles>
        <PageHeader location={location}>Log in to your account</PageHeader>
        <Inner>
          <Form
            onSubmit={event => {
              handleSubmit(event)
            }}
            loading={loading}
            error
          >
            {apiError && handleErrors(apiError)}
            <Segment>
              <Form.Field>
                <Input
                  label="Email"
                  id="email"
                  fluid
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="your@email.com"
                  autoFocus={!isOnMobile()}
                  onChange={handleChange}
                  value={values.email || ''}
                  className={errors.email && 'error'}
                />
                {errors.email && (
                  <div className="message error attached">{errors.email}</div>
                )}
              </Form.Field>
              <Form.Field>
                <Input
                  id="password"
                  fluid
                  name="password"
                  type={hidePassword ? 'password' : 'text'}
                  label="Password"
                  action={
                    <Button
                      onClick={() => {
                        setHidePassword(!hidePassword)
                      }}
                      type="button"
                    >
                      <Icon name={hidePassword ? 'hide' : 'unhide'} />
                    </Button>
                  }
                  autoComplete="current-password"
                  value={values.password || ''}
                  onChange={handleChange}
                  className={errors.password && 'error'}
                />
                {errors.password && (
                  <div className="message error attached">
                    {errors.password}
                  </div>
                )}
              </Form.Field>
              <div className="form-footer">
                <p>
                  Forgot your password? <Link to="/autres/">Click here</Link>
                </p>
                <Button type="submit" className="button-primary">
                  Login
                </Button>
              </div>
            </Segment>
          </Form>
        </Inner>
      </FormStyles>
    </Layout>
  )
}

export default LoginPage

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Email address is required'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid'
  }
  if (!values.password) {
    errors.password = 'Password is required'
  }
  return errors
}
